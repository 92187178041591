@import '_defaultandcolors';

div#content.page{
	background:url(/wp-content/themes/srisaas/theme-img/bg-div-main.jpg) 0 0 repeat-x;
	h1{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;margin-top: 8px;}
	h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;margin-top: -3px;}
	h3{color:$goldlite;text-align:center; text-align:left;font-weight:600;
			color : chocolate;
		    text-align: left;
		    font-weight: 400;
		    font-family: "Roboto", sans-serif !important;
		    font-size: 1.4em;
	}
	h4{color:$antigold;text-align:center;font-size: 1.8em;}
	p{
		font-family: "Open Sans", sans-serif;
		font-size: 1.2em;
	}
	.entry-header{
		-webkit-animation: slide 20s linear infinite;
		background-color: #000000;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23141200' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
		border-bottom: 4px solid #ba9c63;
		
		h1{
			color:#fff;
			text-align:left !important;
			font-family: "Open Sans Condensed", sans-serif;
			font-size: 3em;
			font-weight: 600;
    		//font-family: 'Anton', sans-serif !important;
    		//font-size: 2em;
    		font-weight:500;
		}
		h2{
			color:#bdb9b9;
			text-align:left !important;
			font-weight:100;
			font-family: "Roboto", sans-serif;
    		font-size: 1.3em;		}
	}
	.entry-content{
		//padding-top: 20px;
	}
	.blankstrip{
		margin-top:20px;
		margin-bottom:20px;
		width:100%;
	}
	.striphighlight{
		background-color:#3c3c3c;
			
		//border-top:4px solid $gold;
		border-bottom:4px solid $gold;
		padding-top:20px;
		padding-bottom:20px;
		margin-bottom:20px;
		p{
			color: #c5c3c3;
			font-size: 1.1em;
			a{
				color: #ba9c63;
			    text-decoration: none;
			    background-color: transparent;
			    border-bottom: dotted 1px white;
			}
		}

		h3{color:#FFF;}							
		//background:$striphighlight;
		//margin-bottom:40px;
		//h3{color:$antigold;}
	}
	.highlightbox {
	  display:block;
	  background-color: $goldlite;
	  color: $textcolor6;
	  height: auto;
	  border: 3px solid $gold;
	  border-radius: 15px;
	  margin-bottom:30px;
	  h4 {
	    padding-top: 15px;
	    padding-left: 30px;
	    color: $textcolor3;
	    font-weight:600;
	    text-align:left;
	  }
	  li {
	    padding-left: 30px;
	    list-style: none;
	  }
	  ul li a {
	    text-decoration: none;
	    color: $textcolor6;
	    font-size: 1.2em;
	  }
	}//highlightbox
	#offerbox{
		background:$bgcolor3;
		border:$goldlite solid 2px;
		padding:10px;
		border-radius:10px;
		margin-top:20px;
		margin-bottom:20px;
		p{
			a{color:$gold;}
		}
		h4{font-weight:600;}
	}
	img.packageimage{margin:0px auto;display:block;max-width: 90%;}
	#FAQ,.faq{color: $white; background-color: #AF1B1B; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;margin-bottom:10px;display: block;
		strong{color: #D3C3A4; background-color: #AF1B1B; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;margin-bottom:10px;display: block;
    }
	}
	#nohiddencharges{color: $white; background-color: $gold; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;}
	.showcasepastworks{color:$white; text-decoration:none; background:$gold; font-weight:500; padding: 2px 10px; border-radius: 15px;}
	#formdecor {
	  background-color: #EEEDEB;
	  // background: url(http://www.srisaas.com/wp-content/themes/Karma/pageimages/form-bg.jpg) repeat-x;
	  border:3px solid $gold;
	  padding-left: 30px;
	  border-radius: 15px;
	  textarea::placeholder{padding:10px;}
	  img{max-width:99%;}
	}
	#slideshow p {
    font-size: 1em;
	}
	div.corpseoservices{
		li{color:$textcolor4;font-size: 1.4em;}

	}
	#main.AboutPage{
		.col-md-3.col-sm-3.col-lg-3 img{max-width:90%;}
	}
	#main.OfferWebDesign{
		.col-md-3.col-sm-3.col-lg-3 img{max-width:96%;}
		h4.packageh4.gold {color: $textcolor5;font-weight: 600;font-style: italic;font-size: 1.5em;}
		h4.left{text-align:left;}
		h4.right{text-align:right;}
		.packagedetails{
			.left{text-align:left;}
			.right{text-align:right;}
			p{font-size:1em;}
			p strong{color:$textcolor6;font-style:italic;font-weight:600;}
			
		}
		.package2columns{
			p strong{text-align:center;}

		}
		.packagequestions{
				h6.packagequestion {
				    color: $textcolor3;
				    font-size: 1.6em;
				    font-weight: 600;
				    letter-spacing: 1px;
				    margin-bottom: 0px;
				    padding-bottom: 20px;
				    text-align: center;
				    text-transform: initial;
				}
		text-align:center;
		}		
	}
	#main.ContactPage{
		//margin-top:100px;
		.right{
		h4{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;}
		}
		.left{
		p strong{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;}
		a{color:$antigold;}
		#MapContact{overflow:hidden;}
		}
		.review-box {
		    visibility: hidden;
		}

		.address{
			background-color:#000;
			border:solid 4px #ba9c63;
			border-radius:9px;
			padding-left: 10px;
			margin-top:20px;

			h4{
				text-align:left;
				color:#fff;
				font-size: 1.2em;
    			margin-bottom: 5px;
    			margin-top: 10px;
					
			}
			p{
				text-align:left;
				color:#fff;
				font-size: 1em;
			}
		}

		.contactNotes{
			margin-top:10px;

			p{
				margin-bottom:0px;
				padding-bottom:0px;
			    font-size: 1em;
			    strong{
			    	    font-size: 1em;
			    }

			    a{
		    	    color: mediumseagreen;
				    border-bottom: orange dotted 2px;
			    }
			}
		}
		//h4{color:$antigold;text-align:center;font-size: 1.8em;}
		//h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;}
		//h3{color:$goldlite;text-align:center;font-weight:600;}
		@media (min-width: $screen-md) and (screen) {
			margin-top:100px;
		}		
	}
	#main.DynamicWebPortal{
		ul.webdevtypes{
			li a{color:$textcolor3;}
		}
		ul.webtechtypes{
			li h4 a{text-decoration:underline;color:$textcolor4;font-size: 0.7em;}
		}
	}
	
	#main.OffersPage{
		/* Old Offers Page Template */
		img.packagemainimage{
			width:80%;
		}
		#OfferIntro{
			background-color:$striphighlight;
			.container{ padding-top:20px;padding-bottom:20px;
				p{
					text-align:center;
				}
			}
		}
		#OfferPrice{
			#OfferPriceShow{
				width:50%;
				min-height:50px;
				background-color:#EEEDEB;
				color: $gold;
				text-align:center;
				font-size:1.5em;
				font-weight:600;
				display:block;
				margin:0px auto;
				margin-top:-25px;
				margin-bottom:60px;
				border:solid 4px $gold;
					p{
						padding-top:10px;		
						&.offer-price{display:none;}
					}
				}
		}
		#OfferSection1{
			#OfferDetails{
				background:$secondary-color;
				ul{
					padding-left:0px;
				}
				p{
					color:$goldlite;
					strong{
						color:$gold;
						border-bottom: 1px solid $striphighlight;
					}
				}
			}
			#OfferAddons{
				text-align:left;
				background-color: #efe6e6;
				h3{color:$textcolor6;}
				p{
					strong{border-bottom:solid 2px $gold; }
				}
			}
		}
		@media screen and (min-width:$screen-sm){
	 	#OfferSection1{display:flex;}
		}
		#OfferFaq{
			ul{padding-left:0px;}
		}
		#OfferForm{
			input[type=hidden]{height:1px;}
		}
		.col-md-3.col-sm-3.col-lg-3 img{max-width:96%;}
		h4.packageh4.gold {color: $textcolor5;font-weight: 600;font-style: italic;font-size: 1.5em;}
		h4.left{text-align:left;}
		h4.right{text-align:right;}
		.packagedetails{
			.left{text-align:left;}
			.right{text-align:right;}
			p{font-size:1em;}
			p strong{color:$textcolor6;font-style:italic;font-weight:600;}
			
		}
		.package2columns{
			p strong{text-align:center;}

		}
		.packagequestions{
				h6.packagequestion {
				    color: $textcolor3;
				    font-size: 1.6em;
				    font-weight: 600;
				    letter-spacing: 1px;
				    margin-bottom: 0px;
				    padding-bottom: 20px;
				    text-align: center;
				    text-transform: initial;
				}
		text-align:center;
		}		
	}
	#main.ServicesWebsiteDesign{
		h3{color:$gold;}
		#webdesignuspsid{
			text-align: justify;
			display:flex;
			flex-wrap: wrap; flex-direction: row;
			p{text-align: justify;}
			h4{ color:$textcolor3;font-size:1.3em; font-weight:600;}
			//display:table;
			.webdesignusps {
				//display: table-cell;
			    text-align: justify;
			    background: #f3e6d0;
			    /* margin-right: 0px; */
			    border-right: 10px solid #FFF;
			    flex:1;
			}

		}

		#websitebands{
			.banddetails {
			    padding-left: 10px;
			    padding-top: 10px;
			    padding-bottom: 10px;
			    background-color: lightyellow;
			    border: 3px solid #f0ad4e;
			    border-radius: 14px;
			    margin-bottom: 20px;
			    p{
			    	text-decoration:none;
			    	&:hover{text-decoration:none;}
			    	a{
			    		text-decoration:none;
			    		strong{color:$gold;text-align:center;font-size:1.2em;}
			    		&.btn-default{ color: #f0ad4e; font-weight:600;background-image:none;
						 &:hover{background-color:#f0ad4e;color:#FFF;}	
			    		}
			    	}
			    }
			}
		}	
	}

	#main.CareersPageClass{

		#pleaseNote{
			margin-top:20px;
			border:solid 1px solid #3c3c3c;
			border-radius: 8px;
			p{
				strong{ background:#3c3c3c; color:#fff;}
			}
		}
		.joblisting{
			width:100%;

			h4{color:#ddc28f;font-family:"Open Sans Condensed", sans-serif;}
			
			p{
				width:100%;	
				a{
					text-decoration:none;
					color:#fff;
					&:hover{
						color:lightyellow;
					}
				}
				.location{
					float: right;
				    font-size: 0.8em;
		    		color: lightyellow;
				}
			}
		}
	}

	#main.jobListing{
		#jobSpecifications{
			margin-top:20px;
		}

		i.fas {
		    font-size: 0.8em;
		}
		
		form{
		    background: #3c3c3c;
		    padding-left: 20px;
		    border: solid 4px #ba9c63;
    		padding-top: 20px;
    		border-radius: 9px;
			margin-bottom:40px;
			margin-top: 20px;

    		label{
    			color: #fff;
			    font-family: "Open Sans Condensed",sans-serif;
			    font-size: 1em;
			    .req {
				    color: #FFEB3B;
				    font-size: 1em;
				    margin-left: 4px;
				}

				&.note{
					font-size: 0.8em;
				}
    		}

    		textarea{
    			height:180px;
    		}
			
			input{
			    border: 0px;
			}
    		input[type=submit]{
    			width: 40%;
			    border-radius: 7px;
			    box-shadow: 0px 0px;
			    background: darkred;
			    border: 0px;
			    color: #FFF;
			    font-family: "Open Sans",sans-serif;
    		}

    		.wpcf7-list-item-label{
    			color:#fff;
    			font-family: "Open Sans Condensed",sans-serif;
    		}
	}
	}


	#main.landingPage{
		h1{text-align:center !important;}
		h2{text-align:center !important;}

		p{width:80%;}
	}

}
#partnerPageForm{
	form{
		    background: #3c3c3c;
		    padding-left: 20px;
		    border: solid 4px #ba9c63;
    		padding-top: 20px;
    		border-radius: 9px;
			margin-bottom:40px;

    		label{
    			color: #fff;
			    font-family: "Open Sans Condensed",sans-serif;
			    font-size: 1em;
			    .req {
				    color: #FFEB3B;
				    font-size: 1em;
				    margin-left: 4px;
				}

				&.note{
					font-size: 0.8em;
				}
    		}

    		textarea{
    			height:180px;
    		}
			
			input{
			    border: 0px;
			}
    		input[type=submit]{
    			width: 40%;
			    border-radius: 7px;
			    box-shadow: 0px 0px;
			    background: darkred;
			    border: 0px;
			    color: #FFF;
			    font-family: "Open Sans",sans-serif;
    		}
	}
}

#contactForm{
	form{
		    background: #3c3c3c;
		    padding-left: 20px;
		    border: solid 4px #ba9c63;
    		padding-top: 20px;
    		border-radius: 9px;
			margin-bottom:40px;

    		label{
    			color: #fff;
    			color:#ba9c63;
    			color:#e2c468;
			    font-family: "Open Sans Condensed",sans-serif;
			    font-size: 1em;
			    .req {
				    color: #FFEB3B;
				    font-size: 1em;
				    font-size:0.8em;
				    margin-left: 4px;
				}

				&.note{
					font-size: 1em;
				}
    		}

    		textarea{
    			height:180px;
    		}
			
			input{
			    border: 0px;
			}
    		input[type=submit]{
    			width: 40%;
			    border-radius: 7px;
			    box-shadow: 0px 0px;
			    background: darkred;
			    border: 0px;
			    color: #FFF;
			    font-family: "Open Sans",sans-serif;
    		}

    		.wpcf7-list-item-label{
    			color:#fff;
    			font-family: "Open Sans Condensed",sans-serif;
    		}
	}
}
@import '_defaultandcolors';

body {
  background: #fff;
}

p {
  color: #000;
  font-size: 1.1em;
}

.entry-content {
  input, radio {
    margin: 0;
  }
}

.joblisting {
  padding: 15px;
  margin-top: 10px;
  background-color: #0CC;
  background: #09C;
  width: 70%;
  border-radius: 5px;
  color: white;
  p {
    color: white;
  }
  h4 {
    color: white;
    text-shadow: 0px 0px 0px #666;
  }
  h5 {
    color: white;
    text-shadow: 1px 1px 1px #666;
  }
}

.post_title h2 {
  padding: 5px;
  text-decoration: none;
  padding-left: 7px;
  padding-right: 7px;
  text-shadow: 0px 0px 0px grey;
  text-align: center;
}

#sidebar {
  padding: 0 0 0 5px;
  padding-right: 8px;
  ul li {
    margin-top: -10px;
    margin-bottom: 0px;
  }
}

.blog-posts-shortcode-outer-wrap {
  h4 {
    font-size: 1em;
  }
  .one_fourth {
    height: 321px;
    .small_button span {
      margin-bottom: 0px;
    }
  }
}

/*#main .tools{
height: 0px;
background: white;
padding: 0px;
margin: 0px;
}*/

#offerbox img {
  padding-right: 5px;
}

#content .one_half {
  text-align: justify;
}



.offer-image {
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}

.packages-box {
  background-color: #3c3c3c;
  background-color: black;
  width: 100%;
  border-radius: 15px;
  h5 {
    color: #F5F17D;
    text-shadow: none;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    padding-top: 5px;
    text-align: justify;
    color: #0FC21D;
    font-weight: 500;
  }
  h4 {
    color: #EF7DFA;
    text-shadow: none;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    text-align: justify;
    color: #ECC2C2;
    font-weight: 600;
  }
  h6 {
    color: #51CBF0;
    text-shadow: none;
    margin-left: 10px;
    text-align: justify;
    color: #F05C37;
  }
  p {
    color: #DCDDDE;
    text-shadow: none;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
    padding-bottom: 10px;
  }
}

.content_custom_right_sidebar {
  width: 450px !important;
}

#sub_nav_custom {
  float: left;
  width: 270px;
  float: left;
  padding-left: 15px;
}

/* Offer Menus */

.offer-box {
  display: block;
  width: 182px;
  height: 80px;
  li {
    list-style: none;
  }
}

li .static-website {
  width: 182px;
  background-color: blue;
}

.offer-box li {
  list-style: none;
  width: 182px;
  background-color: green;
  padding-top: 10px 0px;
  text-align: center;
  a {
    text-decoration: none;
    font-size: 18px;
    color: white;
    padding: 4px 0px;
  }
}

li .static-website {
  background-color: red;
  .offer-title a {
    color: red;
  }
}

#homepagetestrun {
  /*background-image: url(images/valueimage.jpg);*/
  height: 300px;
  width: 902px;
  margin: auto;
}

#middle {
  width: 900px;
  border: 0px solid green;
  #block_left {
    border: 0px solid #900;
    width: 278px;
    float: left;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    img {
      float: left;
      padding-right: 10px;
    }
    #content_div {
      h2 {
        margin: 0;
        padding: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        color: #333;
        a:hover {
          color: #066;
        }
      }
      p {
        margin: 0;
        padding: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: #333;
      }
    }
  }
  #block_right {
    border: 0px solid #900;
    width: 278px;
    float: right;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    img {
      padding: 0;
    }
  }
  #clientlist {
    border: 0px solid #900;
    width: 280px;
    height: 64;
    float: right;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    img {
      margin-right: 10px;
      float: right;
    }
  }
}

#homepagetestrun #color_block_left {
  float: left;
  width: 238px;
  border: 1px solid #999;
  margin-top: 10px;
  margin-right: 90px;
  padding: 0;
  p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #333;
    margin: 0px;
    padding: 10px;
  }
  h2 {
    margin-bottom: 0;
    padding-left: 10px;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 16px;
    background-color: #006884;
    color: #FFF;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.prod_listing_home {
  background: #ba9c63;
  h6 {
    color: #ce2a32;
    font-size: 11px;
  }
  h4 {
    font-size: 12px;
    color: #464546;
    margin-top: -30px;
  }
  h5 {
    margin: 0px;
    padding: 0px;
    background-color: #378df7;
    color: #fff;
    width: 150px;
    padding-left: 2px;
  }
  img {
    border: 1px solid #6b686a;
    float: left;
    margin-right: 5px;
  }
}

.content_full_width .prod_listing_home:hover {
  background-color: #F4E090;
}

.prod_listing_home {
  float: left;
  width: 217px !important;
  margin-right: 8px !important;
  border: 1px solid #999;
  padding-left: 4px;
  padding-right: 2px;
  border-radius: 12px;
  background: #ba9c63;
  &:hover {
    background: #ba9c63;
  }
  p {
    color: #ECE6E6;
    font-size: 1.1em;
  }
}



.prod_listing_home h5 {
  text-shadow: none;
}

.price-home-left p {
  margin: 0px;
  padding: 0px;
  background-color: green;
  color: #fff;
  width: 108px;
  padding-left: 2px;
  text-decoration: none;
  height: 18px;
}

.price-home-right a {
  margin: 0px;
  padding: 0px;
  background-color: red;
  color: #fff;
  width: 108px;
  padding-left: 2px;
  text-decoration: none;
}

#slideshow {
  margin: 0px;
  position: relative;
  width: 280px;
  height: 160px;
  > div {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}

#content_div {
  h2 {
    font-weight: 600;
    a {
      text-decoration: none;
    }
  }
  p a {
    text-decoration: none;
    color: green;
    font-weight: 200;
  }
}

.bestwebsitepackages {
  overflow: hidden;
  opacity: 0.3;
  width: 170px;
  background-color: white;
  &:hover {
    opacity: 1;
  }
  li {
    list-style: square;
  }
  a {
    padding: 0px;
    margin: 0px;
    &:hover {
      text-decoration: none;
    }
    color: #3c3c3c;
  }
}

.widgetshowcase {
  height: 100%;
  overflow: hidden;
  opacity: 0.75;
  &:hover {
    height: 100%;
    opacity: 1;
  }
}

.textwidget p {
  margin: 0px;
}

.ecommerce {
  color: blueViolet;
  text-decoration: none;
  &:hover {
    color: green;
    text-decoration: none;
  }
}

#sidebar {
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.individual-offer {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

#Page-Left-Content {
  opacity: 0.1;
  &:hover {
    opacity: 1;
  }
}

#Page-Right-Content {
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}

#shDisplay div#shTitle a {
  font-family: arial;
  font-weight: 400;
  color: #40C2F5;
}

#slideshow h3, h4 {
  text-shadow: none;
}

.faq1 {
  color: #107FBE;
  text-decoration: none;
}

.faq2 {
  color: #178812;
  text-decoration: none;
}

.faq3 {
  color: #F5330E;
  text-decoration: none;
}

.showcase-h3 {
  margin-bottom: 9px;
  background: #6183A8;
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  text-shadow: none;
  color: white;
}

#menu-main-nav li strong {
  color: #F1DF08 !important;
  color: #E9E073 !important;
  color: #F5E200 !important;
}

#sidebar h3 {
  color: #222;
  font-size: 1.2em;
  text-align: center;
}

.bestsolutionpackages {
  overflow: hidden;
  opacity: 0.3;
  width: 170px;
  background-color: white;
  height: 57px;
  &:hover {
    opacity: 1;
    height: 100%;
  }
}

.bestwebsitepackages li {
  list-style: square;
}

.bestsolutionpackages a {
  padding: 0px;
  margin: 0px;
  &:hover {
    text-decoration: none;
  }
  color: #3c3c3c;
}

#offerbox .offerbox-offer {
  background: #585A58;
  width: 85%;
  border: black 1px solid;
  font-size: 13px;
  width: 340px;
  border-radius: 15px;
  margin: 2px;
  h5 {
    margin: 0px;
    padding: 1px;
    padding-left: 5px;
    font-weight: 400;
    color: white;
    background: #BA9C63;
    text-shadow: none;
    font-family: arial;
  }
  p {
    margin: 3px;
    padding: 5px;
    color: #ABE1F1;
  }
  a {
    text-decoration: none;
  }
  h6 {
    margin: 0px;
    padding: 0px;
    padding-left: 5px;
    color: #DAD3D3;
    font-family: arial;
    font-weight: 200;
  }
}

.toolbar-right .textwidget p {
  color: #FDFFFD;
  font-size: 1.3em;
}

/*-----------------------------------*/

.wrapper {
  width: 170px;
  margin: 0 auto;
}



aside#secondary.widget-area{
      margin-top: 50px;
      opacity:0.2;
      &:hover{opacity:1;}
      img {
          display: block;
          //margin: 0px auto;
          // max-width: 90%;
          //width: 90%;
      }
      >.widgetshowcase ul {
          padding: 0px;
      }
      h2.expand_heading {
          margin: 0 0 2px 0;
          // height: 38px;
          // width: 170px;
          // height: 57px;
          font-size: 12px;
          font-weight: normal;
          float: left;
          a {
            color: #fff;
            text-decoration: none;
            display: block;
          }
        }
      .toggle_container{
          margin: 0 0 5px;
        padding: 0;
        border-top: 1px solid #d6d6d6;
        background: #ffffff;
        /*background: none;*/
        overflow: hidden;
        font-size: 1.2em;
        width: 170px;
        clear: both;
        .box {
          img {
          display: block;
          //margin: 0px auto;
          max-width: 90%;
          //width: 90%;
        }
          ul {
            margin: 0px;
            padding: 0px;
            font-size: 12px;
          }
          li {
            margin-bottom: 10px;
          }
          a {
            color: #666;
            text-decoration: none;
            width:100%;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      } 
    }

.uds-pricing-table.light tbody {
  td, th {
    border: 1px solid #b2b5bb;
    text-shadow: none;
    font-weight: 600;
    color: #3c3c3c;
  }
}

#main #content .uds-pricing-table.light tbody {
  td, th {
    border: 1px solid #b2b5bb;
    text-shadow: none;
    font-weight: 600;
    color: #3c3c3c;
  }
}

.uds-pricing-table.light tr.even {
  td, th {
    background-color: rgb(214, 230, 41);
  }
}

#main #content .uds-pricing-table.light tr.even {
  td, th {
    background-color: rgb(214, 230, 41);
  }
}

.uds-pricing-table.light tr.even {
  th.featured, td.featured {
    background-color: rgb(236, 229, 139);
  }
}

#main #content .uds-pricing-table.light tr.even {
  th.featured, td.featured {
    background-color: rgb(236, 229, 139);
  }
}

.uds-pricing-table.light thead th .uds-product-name, #main #content .uds-pricing-table.light thead th .uds-product-name {
  background-color: rgb(117, 194, 47);
  text-shadow: none;
  color: #fff;
}

.portfolio_content h3 {
  font-size: 16px;
  text-align: center;
}

/*---------Suman's Blog Code--------*/
@charset "utf-8";

/* CSS Document */

.post_thumb {
  width: 230px;
  height: 225px;
  /*background:url(images/_global/global-sprite.png) -1px -921px no-repeat;*/
  position: relative;
  margin: 0 0 7px;
  float: left;
}

.post_thumb_load {
  width: 200px;
  height: 200px;
  background: url(images/_global/preload.gif) center center no-repeat;
}

.post_thumb_shadow_load {
  width: 556px;
  height: 255px;
  background: url(images/_global/preload-white.gif) center center no-repeat;
}

.post_thumb img {
  /*margin:8px 0 0 9px;*/
  padding: 5px;
  border: 1px solid #ccc;
  width: 215px;
  height: 200px;
}

.single_blog_wrap {
  .post_thumb {
    width: 560px;
    float: none;
    width: 560px;
    float: none;
  }
  .post_thumb_load {
    width: 560px;
  }
}

/*---------Suman's Blog Code END--------*/

.content-blog {
  p {
    font-family: verdana;
    > strong {
      font-family: verdana;
    }
  }
  a {
    font-family: verdana;
  }
}

/*.post_content h5{ background-color: none;}
.post_title h2 {background-color: none;}*/
/*.post_thumb {background:none;}*/

.post_title h2 a {
  color: #4073CE;
  /*background-color: none;*/
}

#header .header-area {
  padding: 18px 0 0px;
}

#main .main-area {
  padding: 0px 0 5px;
}

#menu-main-nav li strong {
  font-family: arial;
  font-weight: 400;
  color: #F6FF8B !important;
}

#sidebar ul {
  margin: 8px 0 0 0;
}

a.belowformpackage {
  text-decoration: none;
  color: #F5552D;
  strong {
    color: #515155;
  }
}

.post_title h2 {
  color: #13B461;
  font-size: 18pt;
  margin-left: -9px;
  font-family: arial;
  font-weight: 600;
  letter-spacing: 1px;
}

.wp-caption {
  img {
    max-width: 100%;
    height: auto;
  }
  max-width: 100%;
  max-height: 100%;
}

#main .post_content p {
  text-align: justify;
  font-size: 11pt;
  font-family: arial;
}

.post_content {
  h6 {
    color: #13B461;
    font-family: arial;
    font-size: 11pt;
  }
  h3 {
    color: #EB18A7;
  }
  h5 {
    letter-spacing: 2px;
    color: #3F91DD;
    text-shadow: none;
  }
}

.blog_wrap p {
  margin-top: 20px;
}

.post_content h5 a {
  color: #13B461;
}

.blog_wrap .post_title h2 a, .post_title h2 a {
  color: #13B461 !important;
}

.post_thumb img {
  width: 200px;
  height: 200px;
}

/*--------*/

.home_offer_link {
  text-decoration: none;
  color: #72B311;
  color: #2D361F;
  font-weight: 800;
  padding-right: 4px;
  font-size: 12px;
  margin-left: 5px;
  &:hover {
    text-decoration: none;
  }
}

.home_offer_link_bar {
  text-decoration: none;
  color: #5D95F8;
  font-weight: 800;
  font-size: 1.3em;
}

h4.tm_lcptu_post_title {
  font-size: 1em;
}

/*.big-banner #header .header-holder{background:none;}
#header .header-holder{background:none;}*/
/* -------------------------------------*/

.header-holder {
  background: #3c3c3c;
}

.big-banner .rays, #header .header-holder {
  background: #3D3B3B !important;
}

.login h1 a {
  width: 100% !important;
}

#wrapper {
  background: #FDFDFD !important;
}

p {
  text-shadow: none;
  color: #7B6E6E;
  font-size: 1.2em;
}

.sidebar-widget {
  &:hover {
    opacity: 1;
  }
  opacity: 0.1;
}

div#main .main-area #content > p > img {
  width: 100% !important;
  height: auto !important;
}

#main .home-main-area {
  width: 980px !important;
}

div#main .main-area #content {
  .one_half, .one_half_last {
    width: 47%;
  }
}

.offer-page-slideshow {
  background: url('http://www.srisaas.com/wp-content/themes/Karma/pageimages/slideshow-bg.png');
  background-size: 411px 160px;
  background-repeat: no-repeat;
  height: 160px;
}

#offer-page-slideshow-container {
  width: 411px;
  height: 160px;
  overflow: hidden;
}

img.home16thumbs {
  border: 2px solid #ba9c63;
}

hr.home16line {
  color: #3c3c3c;
  opacity: 0.2;
  margin-bottom: 20px;
}

#main .main-area {
  max-width: 100% !important;
  #content {
    max-width: 100% !important;
  }
}

.home-main-area.three_fourth {
  max-width: 75% !important;
}

/**** 2k17 ****/

hr.gold {
  border: 2px solid #BA9C63;
}

div#main.totalfullwidth #content {
  width: 100% !important;
  padding: 0px;
  h1 {
    text-align: center;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 2.46em;
    color: #BA9C63;
  }
  h2 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 700;
    color: #696969;
  }
}

img.packagemainimage {
  margin: 0px auto;
  width: auto;
  height: auto;
  display: block;
}

div#main.totalfullwidth {
  .row {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    min-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: auto;
    .contentcontainer {
      width: 1170px;
      margin: 0px auto;
      display: block;
      max-width: 100%;
    }
  }
  #content .contentcontainer {
    h3 {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      color: #696969;
      &.gold {
        color: #c78507 !important;
      }
    }
    h4 {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #696969;
    }
    p {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #696969;
    }
  }
  .row .contentcontainer {
    &.left p {
      text-align: left !important;
    }
    &.right p {
      text-align: right !important;
    }
  }
}

.threefourthcontent {
  &.left p {
    text-align: left !important;
  }
  &.right p {
    text-align: right !important;
  }
  overflow: hidden;
  img {
    border: solid 3px BA9C63;
  }
  &.left img {
    float: right;
  }
  &.right img {
    float: left;
  }
}

.content_full_width {
  .three_fourth {
    width: 71% !important;
  }
  .one_fourth {
    width: 25% !important;
  }
  .one_third {
    width: 31% !important;
    margin-right: 0px !important;
  }
  .one_half {
    width: 45% !important;
  }
}

.contentcontainer .one_half ul {
  padding-left: 10%;
}

p.sololink {
  width: 100%;
  &.left {
    float: left;
    text-align: left !important;
    padding-left: 8%;
  }
  a {
    color: #BA9C63;
    color: #c78407;
    font-style: italic;
    text-decoration: none;
    border-bottom: #e8a629 solid 1px;
    &:hover {
      border-bottom: #0ca565 solid 1px;
    }
  }
}

a {
  &.sololink, &.postbloglink {
    color: #af7406;
    text-decoration: none;
    border-bottom: #e8a629 solid 1px;
    &:hover {
      border-bottom: #0ca565 solid 1px;
    }
  }
}

.contentcontainer {
  h4.packageh4 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600 !important;
    font-style: italic;
    &.left {
      text-align: left !important;
    }
    &.right {
      text-align: right !important;
    }
    &.gold {
      /*color: #BA9C63 !important; */
      color: #bf8310 !important;
    }
  }
  .gold {
    color: #BA9C63 !important;
  }
  h5.packageh5 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600 !important;
    font-style: italic;
    &.left {
      text-align: left !important;
    }
    &.right {
      text-align: right !important;
    }
    &.gold {
      color: #795819 !important;
    }
  }
  .gold {
    color: #BA9C63 !important;
  }
  
}

#showsticker {
  position: absolute;
  width: 100%;
  height: 1px;
  display: block;
}

div#stickercontact {
  position: relative;
  bottom: 0px;
  background: red;
  width: 100%;
  height: auto;
  background: #f9e88a;
  border-top: solid 4px #BA9C63;
  border-bottom: solid 4px #BA9C63;
  box-shadow: #3c3c3c 2px 2px 2px;
  margin-bottom: 5px;
}

.contentcontainer p strong {
  color: #807e7e;
  font-style: italic;
}

#stickercontact {
  p {
    strong {
      color: #3c3c3c;
      color: darkcyan;
      font-style: italic;
      font-size: 1.3em;
    }
    margin-bottom: 5px;
    a {
      /*color: darkgreen;*/
    }
  }
  h4 {
    margin-bottom: 4px;
    margin-top: 4px;
    color: brown !important;
    font-style: normal;
  }
  .one_third {
    padding-bottom: 0px;
    width: 32%;
    float: left;
    text-align: center;
  }
}

div#enquiry {
  width: 50%;
  margin: 0px auto !important;
  display: block;
  border-radius: 15px;
  /*  background: url(http://www.srisaas.com/wp-content/themes/Karma/pageimages/form-bg.jpg) repeat-x; */
  background: #f9e88a;
  border-top: solid 4px #BA9C63;
  border-bottom: solid 4px #BA9C63;
  border-left: 2px solid #e6bd29;
  border-right: solid 2px #e6bd29;
  #formdecor {
    margin-top: 20px;
  }
}

#enquiry label {
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

#footer_bottom {
  position: relative;
  /*for the sticker*/
}

.post_content h1 {
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #BA9C63;
  text-align: center;
  line-height: 1.2em;
}

.single_blog_wrap {
  .post_title h2 {
    color: #ce6969;
  }
  h3, h4, h5, h6 {
    color: #bf8310;
    font-weight: 600;
    font-style: italic;
  }
  h4 {
    font-size: 1.4em;
  }
  h5 {
    font-size: 1.2em;
  }
  h6 {
    font-size: 1.1em;
  }
  a {
    text-decoration: none;
    font-style: italic;
    color: #1a80da;
    border-bottom: solid 1px forestgreen;
    &:hover {
      border-bottom: solid 1px #ec8439;
    }
  }
}

/*#main{background:none;}*/

.single-post {
  #main .main-area {
    width: 100%;
  }
  .content_blog {
    width: 70% !important;
  }
  .post_title {
    width: 100%;
    text-align: center;
  }
  .post_content, .single_blog_wrap {
    width: 100%;
  }
  #sidebar.sidebar_blog {
    width: 26% !important;
    min-width: 240px !important;
  }
  .post_content p {
    text-align: justify;
    font-size: 1.4em;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6em;
  }
}

div#home-posts.totalfullwidth .row .contentcontainer {
  width: 1170px;
  margin: 0px auto;
  display: block;
  max-width: 100%;
}



/* Home - Projects */

.modal-slide img {
  width: 90%;
  height: auto;
  max-height: 400px;
}

#modal-works.project .project-details p a {
  text-decoration: none;
  color: #FFF !important;
}

#formdecor fieldset label {
    margin-bottom: 10px;
    margin-top: 20px;
    color:#ce4e4e;
}
@import '_defaultandcolors';


div#primary{
	&.singleproject{
		a.back-to-project-list{margin-top:20px; color:$textcolor3;text-decoration:none;}
		h1{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;margin-top: 8px;}
		h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;margin-top: -3px;}
		h3{color:$goldlite;text-align:center;font-weight:600;}
		h4{color:$antigold;text-align:center;font-size: 1.8em;}
		h1{
		text-align:center;
		// color:$gold;
		// font-weight:600;
		}
		p{font-size:1em;}
		strong{color:$gold;}
		img{
			max-width:96%;
			border: solid 2px $gold;
		}
		.projectdescription{
			text-align:justify;
		}
		.techbrief{
			text-align:justify;
		}
		.projectlink{
			a{text-decoration:none; color:$textcolor3;font-weight:600;}
		}
		.project_main_image{margin-bottom:40px;}
		ul.projecttech{
			li{
				    list-style: none;
				    float: left;
				    margin-right: 10px;
				    background: $striphighlight;
				    padding-left: 10px;
				    padding-right: 10px;
				    border-radius: 10px;
			}
		}
	
	}
	&.projectspage{
	
		//ul.home-projects-list{	
				h1{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;margin-top: 8px;}
				h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;margin-top: -3px;}
				h3{color:$goldlite;text-align:center;font-weight:600;}
				h4{color:$antigold;text-align:center;font-size: 1.8em;font-size:1em;font-size:0.8em;}
				h5{color:$gold;text-align:center;font-size: 1.3em;font-size:0.9em;font-size:0.8em;}
				a{
					text-decoration:none;
					h4{color:$antigold;text-align:center;font-size: 1.4em;margin-bottom:0px;font-size:0.8em;}
					h5{color:$gold;text-align:center;font-size: 1.2em;font-size:0.8em;}
					
				}
				img{
					    border: solid 2px $gold;
					    width:auto;
					    max-width:90%;
				}
		// }
    }
}
// 	background:url(/wp-content/themes/srisaas/theme-img/bg-div-main.jpg) 0 0 repeat-x;
// 	h1{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;margin-top: 8px;}
// 	h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;margin-top: -3px;}
// 	h3{color:$goldlite;text-align:center;font-weight:600;}
// 	h4{color:$antigold;text-align:center;font-size: 1.8em;}
// 	.striphighlight{
// 		background:$striphighlight;
// 		margin-bottom:40px;
// 		h3{color:$antigold;}
// 	}
// 	.highlightbox {
// 	  display:block;
// 	  background-color: $goldlite;
// 	  color: $textcolor6;
// 	  height: auto;
// 	  border: 3px solid $gold;
// 	  border-radius: 15px;
// 	  margin-bottom:30px;
// 	  h4 {
// 	    padding-top: 15px;
// 	    padding-left: 30px;
// 	    color: $textcolor3;
// 	    font-weight:600;
// 	    text-align:left;
// 	  }
// 	  li {
// 	    padding-left: 30px;
// 	    list-style: none;
// 	  }
// 	  ul li a {
// 	    text-decoration: none;
// 	    color: $textcolor6;
// 	    font-size: 1.2em;
// 	  }
// 	}//highlightbox
// 	#offerbox{
// 		background:$bgcolor3;
// 		border:$goldlite solid 2px;
// 		padding:10px;
// 		border-radius:10px;
// 		margin-top:20px;
// 		margin-bottom:20px;
// 		p{
// 			a{color:$gold;}
// 		}
// 		h4{font-weight:600;}
// 	}
// 	img.packageimage{margin:0px auto;display:block;max-width: 90%;}
// 	#FAQ,.faq{color: $white; background-color: #AF1B1B; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;margin-bottom:10px;display: block;
// 		strong{color: #D3C3A4; background-color: #AF1B1B; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;margin-bottom:10px;display: block;
//     width: 45%;}
// 	}
// 	#nohiddencharges{color: $white; background-color: $gold; border-radius: 10px; padding: 3px; text-decoration: none; padding-left: 7px; padding-right: 7px;}
// 	.showcasepastworks{color:$white; text-decoration:none; background:$gold; font-weight:500; padding: 2px 10px; border-radius: 15px;}
// 	#formdecor {
// 	  background-color: #EEEDEB;
// 	  // background: url(http://www.srisaas.com/wp-content/themes/Karma/pageimages/form-bg.jpg) repeat-x;
// 	  border:3px solid $gold;
// 	  padding-left: 30px;
// 	  border-radius: 15px;
// 	  textarea::placeholder{padding:10px;}
// 	  img{max-width:99%;}
// 	}
// 	#slideshow p {
//     font-size: 1em;
// 	}
// 	div.corpseoservices{
// 		li{color:$textcolor4;font-size: 1.4em;}

// 	}
// 	#main.AboutPage{
// 		.col-md-3.col-sm-3.col-lg-3 img{max-width:90%;}
// 	}
// 	#main.OfferWebDesign{
// 		.col-md-3.col-sm-3.col-lg-3 img{max-width:96%;}
// 		h4.packageh4.gold {color: $textcolor5;font-weight: 600;font-style: italic;font-size: 1.5em;}
// 		h4.left{text-align:left;}
// 		h4.right{text-align:right;}
// 		.packagedetails{
// 			.left{text-align:left;}
// 			.right{text-align:right;}
// 			p{font-size:1em;}
// 			p strong{color:$textcolor6;font-style:italic;font-weight:600;}
			
// 		}
// 		.package2columns{
// 			p strong{text-align:center;}

// 		}
// 		.packagequestions{
// 				h6.packagequestion {
// 				    color: $textcolor3;
// 				    font-size: 1.6em;
// 				    font-weight: 600;
// 				    letter-spacing: 1px;
// 				    margin-bottom: 0px;
// 				    padding-bottom: 20px;
// 				    text-align: center;
// 				    text-transform: initial;
// 				}
// 		text-align:center;
// 		}		
// 	}
// 	#main.ContactPage{
// 		margin-top:100px;
// 		.right{
// 		h4{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-h;}
// 		}
// 		.left{
// 		p strong{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;}
// 		a{color:$antigold;}
// 		#MapContact{overflow:hidden;}
// 		}
// 		.review-box {
// 		    visibility: hidden;
// 		}
// 		//h4{color:$antigold;text-align:center;font-size: 1.8em;}
// 		//h2{color:$gold;text-align:center;margin-bottom:20px;font-weight:600;font-family:$font-stack-body;}
// 		//h3{color:$goldlite;text-align:center;font-weight:600;}
// 		@media (min-width: $screen-md) and (screen) {
// 			margin-top:100px;
// 		}		
// 	}
// 	#main.DynamicWebPortal{
// 		ul.webdevtypes{
// 			li a{color:$textcolor3;}
// 		}
// 		ul.webtechtypes{
// 			li h4 a{text-decoration:underline;color:$textcolor4;font-size: 0.7em;}
// 		}
// 	}
	
// 	#main.OffersPage{
// 		/* Old Offers Page Template */
// 		img.packagemainimage{
// 			width:80%;
// 		}
// 		#OfferIntro{
// 			background-color:$striphighlight;
// 			.container{ padding-top:20px;padding-bottom:20px;
// 				p{
// 					text-align:center;
// 				}
// 			}
// 		}
// 		#OfferPrice{
// 			#OfferPriceShow{
// 				width:50%;
// 				min-height:50px;
// 				background-color:#EEEDEB;
// 				color: $gold;
// 				text-align:center;
// 				font-size:1.5em;
// 				font-weight:600;
// 				display:block;
// 				margin:0px auto;
// 				margin-top:-25px;
// 				margin-bottom:60px;
// 				border:solid 4px $gold;
// 					p{
// 						padding-top:10px;		
// 						&.offer-price{display:none;}
// 					}
// 				}
// 		}
// 		#OfferSection1{
// 			#OfferDetails{
// 				background:$secondary-color;
// 				ul{
// 					padding-left:0px;
// 				}
// 				p{
// 					color:$goldlite;
// 					strong{
// 						color:$gold;
// 						border-bottom: 1px solid $striphighlight;
// 					}
// 				}
// 			}
// 			#OfferAddons{
// 				text-align:left;
// 				background-color: #efe6e6;
// 				h3{color:$textcolor6;}
// 				p{
// 					strong{border-bottom:solid 2px $gold; }
// 				}
// 			}
// 		}
// 		@media screen and (min-width:$screen-sm){
// 	 	#OfferSection1{display:flex;}
// 		}
// 		#OfferFaq{
// 			ul{padding-left:0px;}
// 		}
// 		#OfferForm{
// 			input[type=hidden]{height:1px;}
// 		}
// 		.col-md-3.col-sm-3.col-lg-3 img{max-width:96%;}
// 		h4.packageh4.gold {color: $textcolor5;font-weight: 600;font-style: italic;font-size: 1.5em;}
// 		h4.left{text-align:left;}
// 		h4.right{text-align:right;}
// 		.packagedetails{
// 			.left{text-align:left;}
// 			.right{text-align:right;}
// 			p{font-size:1em;}
// 			p strong{color:$textcolor6;font-style:italic;font-weight:600;}
			
// 		}
// 		.package2columns{
// 			p strong{text-align:center;}

// 		}
// 		.packagequestions{
// 				h6.packagequestion {
// 				    color: $textcolor3;
// 				    font-size: 1.6em;
// 				    font-weight: 600;
// 				    letter-spacing: 1px;
// 				    margin-bottom: 0px;
// 				    padding-bottom: 20px;
// 				    text-align: center;
// 				    text-transform: initial;
// 				}
// 		text-align:center;
// 		}		
// 	}

// @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700');
// @import url('https://fonts.googleapis.com/css?family=Nunito');

$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$screen: "only screen";
$xs-up: $screen;
$xs-only: "#{$screen} and (max-width: #{$screen-xs-max})";

$sm-up: "#{$screen} and (min-width: #{$screen-sm-min})";
$sm-only: "#{$screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";

$md-up: "#{$screen} and (min-width: #{$screen-md-min})";
$md-only: "#{$screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})";

$lg-up: "#{$screen} and (min-width: #{$screen-lg-min})";



$font-stack:    'Open Sans Condensed', sans-serif;
$font-stack-body: 'Nunito', sans-serif;
$font-stack-p: 'Nunito', sans-serif;
$font-stack-h:'Open Sans Condensed', sans-serif;

$primary-color: #333;
$secondary-color: #3c3c3c;
$bgcolorlight:#515151;
$bgcolordark:#3D3B3B;

$bgfooter:#9C9C9C;
$bgfooterbottom: #343434;

$footerh3border:#3E4055;
$footerbloglistcolor: #DDD;
$white:#FFF;

$textfooterbottom: #868686 ;
$textaboveheader:#CCC;
//$goldlite:#F6FF8B;
$goldlite:#ddc28f;
$gold:#ba9c63;
$antigold:#838c82;
$striphighlight:#ffe6a0;
$striphighlight2:#fbe6c7;

$bgcolor1: #450f63;
$bgcolor2: #3c3c3c;
$bgcolor2:#bf9000;
$bgcolor3: #eeeeee;
$bgcolor4: #e8e8e8;
$bgcolor5:#008000;
$bgcolor5:#bf9000;
$green: #bf9000;
$textcolor1:#FFF;
$textcolor2:#9b9c9d;
$textcolor3:#AF1B1B;
//$textcolor4:#5a5b5c;
$textcolor4:#bf9000;

$textcolor5:#bf8310;
$textcolor6:#807e7e;

$bgform:#ded7d1;
$signupbutton:#92b3d5;
$signupcolor:#FFF;
$bordercolor1:#d0cfcf;
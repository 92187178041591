/*!
Theme Name: Srisaas
Theme URI: http://underscores.me/
Author: SriSAAS
Author URI: http://www.srisaas.com
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: srisaas
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

Srisaas is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.io/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
// @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700');
// @import url('https://fonts.googleapis.com/css?family=Nunito');
@import '_defaultandcolors';
@import '_old';
@import '_page';
@import '_forms';
@import '_types';
@import '_misc';
// $screen-xs:                  480px;
// $screen-xs-min:              $screen-xs;

// // Small screen / tablet
// $screen-sm:                  768px;
// $screen-sm-min:              $screen-sm;

// // Medium screen / desktop
// $screen-md:                  992px;
// $screen-md-min:              $screen-md;

// // Large screen / wide desktop
// $screen-lg:                  1200px;
// $screen-lg-min:              $screen-lg;

// // So media queries don't overlap when required, provide a maximum
// $screen-xs-max:              ($screen-sm-min - 1);
// $screen-sm-max:              ($screen-md-min - 1);
// $screen-md-max:              ($screen-lg-min - 1);

// $screen: "only screen";
// $xs-up: $screen;
// $xs-only: "#{$screen} and (max-width: #{$screen-xs-max})";

// $sm-up: "#{$screen} and (min-width: #{$screen-sm-min})";
// $sm-only: "#{$screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";

// $md-up: "#{$screen} and (min-width: #{$screen-md-min})";
// $md-only: "#{$screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})";

// $lg-up: "#{$screen} and (min-width: #{$screen-lg-min})";



// $font-stack:    'Open Sans Condensed', sans-serif;
// $font-stack-body: 'Nunito', sans-serif;
// $font-stack-p: 'Nunito', sans-serif;
// $font-stack-h:'Open Sans Condensed', sans-serif;

// $primary-color: #333;
// $secondary-color: #3c3c3c;
// $bgcolorlight:#515151;
// $bgcolordark:#3D3B3B;

// $bgfooter:#9C9C9C;
// $bgfooterbottom: #343434;

// $footerh3border:#3E4055;
// $footerbloglistcolor: #DDD;
// $white:#FFF;

// $textfooterbottom: #868686 ;
// $textaboveheader:#CCC;
// $goldlite:#F6FF8B;
// $gold:#ba9c63;
// $antigold:#838c82;

// $bgcolor1: #450f63;
// $bgcolor2: #3c3c3c;
// $bgcolor2:#bf9000;
// $bgcolor3: #eeeeee;
// $bgcolor4: #e8e8e8;
// $bgcolor5:#008000;
// $bgcolor5:#bf9000;
// $green: #bf9000;
// $textcolor1:#FFF;
// $textcolor2:#9b9c9d;
// $textcolor3:#450f63;
// $textcolor4:#5a5b5c;
// $textcolor4:#bf9000;
// $bgform:#ded7d1;
// $signupbutton:#92b3d5;
// $signupcolor:#FFF;
// $bordercolor1:#d0cfcf;
html{
	@media screen and (min-width: $screen-md) {
		overflow-x: hidden;

	}
}
body{
	padding:0px;
	font-family: $font-stack-body;
}

    
h1,h2,h3,h4,h5,h6{font-family:$font-stack-h;}
p,a{font-family:$font-stack-p;}
a{text-decoration:none;}
li{list-style:none;}


.entry-content{
	img{max-width:100%;height:auto;}
}

#formdecor{
	input{max-width:96%;}
	textarea{max-width:96%;}
}

#aboveheader{
	background:$bgcolorlight;
	color:$textaboveheader;
	p{font-size:1em;}
	.contact{color:$white;
		&a:hover{text-decoration:none;}
	}
	a{color:$white;}
	#ahleft{
		p{ font-size:0.8em;margin-top:10px;color:$goldlite;}
	}
	#ahright{
		p{float:right;color:$white;}
	}
}

#header{
	&.rays{
	background: url(/wp-content/themes/srisaas/theme-img/rays.png) 50% 0 no-repeat;
	}
	background-color:$bgcolordark;
	padding-top:10px;
    border-bottom: 4px solid #ba9c63;
	.navbar-nav{float:right;}
	.nav{right:-140px;}
	.nav .open>a, .nav .open>a:hover, .nav .open>a:focus{background:$bgcolordark;}
	li{
		&:hover{background:none;background:$bgcolordark;border-radius-top-left:10px;border-radius-top-right:10px;}

		ul{background:$bgcolordark;}
		ul li{
			background:none;
			background:$bgcolordark;
			&:hover{background:none;background:$bgcolorlight;}
				a{background:none;
					&:hover{background:none;background: #ddc28f;color: #000;}
				}
			}
		a{color:$goldlite;
			&:hover{background:none;
			}
		}
	}
	@media screen and (min-width: $screen-lg) {
		.dropdown:hover .dropdown-menu {
			display: block;
		    margin-top: -0.5em;
		    background:#3c3c3c;
		    border:solid 1px #ddc28f;
		    box-shadow: 2px 4px #f1dc77;
			li{
				a{
					&:hover{
						color:#fff;
					}
				}
			}		
		}
	}
	@media (min-width:$screen-md) and (max-width: $screen-lg) {
	.dropdown:hover .dropdown-menu {
	display: block;
	}
	}
	//Below are the Tablet Mobile Resolutions
	@media screen and (max-width: $screen-xs) {
    &.rays{background: none;}
    .nav{right:0px;}
    img{
    	margin:0px auto;
		display:block;
    	}
    ul.nav{
    margin: 0px auto;
    display: block;
    float: none;
	}
    .nav>li {
    position: relative;
    display: block;
    margin: 0px auto;
    width: 80%;
	}	
    li a {
    text-align: center;
	}	
	}
	@media (min-width:$screen-xs) and (max-width: $screen-sm) {
	&.rays{background: none;}
	.nav{right:0px;}
	img{
    	margin:0px auto;
		display:block;
    }
    ul.nav{
    margin: 0px auto;
    display: block;
    float: none;
	}
    .nav>li {
    position: relative;
    display: block;
    margin: 0px auto;
    width: 80%;
	}	
    li a {
    text-align: center;
	}

	}
	
	@media screen and (max-width: $screen-lg) {
	.navbar-nav{
			width: 100%;
			
		.dropdown-menu{
			background:#5d5b5b;
		}

		}	
	}

	.navbar-toggler-icon{
		//border: solid 1px #fff;
   		z-index: 999;
   		background-image:url(/wp-content/themes/srisaas/theme-img/icons8-menu-filled.png);
	}
}

#homepagebanner{
	//background-color:$bgcolordark;
	background: linear-gradient(0deg, $white, $bgcolordark);
	background: #3c3c3c;
	-webkit-animation: slide 20s linear infinite;
		
	//SVG BACKGROUND//
	background-color: #000000;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23241f00' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
		
	background-color: #000000;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23141200' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
	
	padding-bottom: 40px;
	
	p.letstalk{
		text-align:center;color:#FFF;
		margin: 0px;
    	margin-left: -200px;
	}
	button{
	    display: block;
	    margin: 0px auto;
	    background: darkred;
	    color: #FFF;
	    font-weight: 600;
	    font-size: 1.5em;
	    padding: 3px 100px;
	    border-radius: 7px;
	}
	.fa, .fas {
    font-weight: 900;
    font-size: 0.8em;
    margin-right: 4px;
    color: #ddc28f;
	}
	.left{
		.box{float:right;}
		h3{ 
			text-align:left;
			font-weight:100 !important;
			font-size: 1.5em;
    		//color: #FFF !important;
   			text-align: left !important;
			margin-right: 40px;
			margin-bottom: 1em;
   			margin-top: 1em;
   			a{
   				font-family:"Open Sans Condensed", sans-serif;
    			text-decoration: none;
    			color: #FFF !important;
    			font-size: 0.8em !important;
    		}
   		}
	}
	.right{
		.box{float:left;}
		h3{ 
			text-align:left;
			font-weight:100 !important;
			font-size: 1.5em;
    		text-align: left !important;
   			margin-left: 40px;
   			margin-bottom: 1em;
    		margin-top: 1em;
    		a{
    			font-family:"Open Sans Condensed", sans-serif;
    			text-decoration: none;
    			color: #FFF !important;
    			font-size: 0.8em !important;
    		}
		}
	}
	h1{
		color:#fff !important;
		font-weight:100 !important;
		font-size:4em !important;
		margin-top:40px !important;
		span.strong {
    	font-weight: 600;
		}
	}
	h2{
		color: #efe4c4 !important;
	    text-align: center !important;
	    margin-bottom: 20px !important;
	    font-weight: 100 !important;
	    font-family: "Open Sans Condensed", sans-serif !important;
	}

	.home-banner-wrap {
    width: 940px;
    height: 419px;
    background: url(/wp-content/themes/srisaas/theme-img/bg-home-banner.png) 0 0 no-repeat;
    position: relative;
    //margin: 87px auto 0;
	margin: 0px auto;
	}
	.home-banner-sub-full {
    width: 860px;
    height: 276px;
    background: url(/wp-content/themes/srisaas/theme-img/shadowBG-full.jpg) 0 0 no-repeat;
    top: 40px;
    right: 40px;
    position: absolute;
	}
	// #slider {
 //    width: 940px !important;
 //    height: 419px !important;
 //    padding: 0px;
 //    margin: 0px auto;
 //    max-width: 97%;
 //    height: auto;
	// }
	img{width:100%;height:100%; border: $white solid 8px;}
	@media screen and (max-width: $screen-xs) {
		//background:none;
		.home-banner-wrap {
		    width: 100%;
		    height: auto;
			background:none;
			padding: 0px;
			ul{width:100%;height:auto;padding:0px;
			  li{width:100%;height:auto;}
			  }
			 }
		
		.home-banner-sub-full {
	    width: 100%;
	    margin:0px auto;
	    display: block;
	    height: auto;
	    position: relative;
	    background:none;
	    top:10px;
	    right:0px;
    	img{display:block;margin:0px auto;max-width:100%;height:auto;border:solid $white 4px;}
    	}
    	h1{font-size:3em !important;}

    	.left{
    		.box{float:none;}
    		h3{ 
    			a{
    				text-align:center;
    				margin:0px !important;
    			}
    		}
    	}
    	.right{
    		.box{float:none;}
    		h3{
    			a{
    				text-align:center;
    				margin:0px !important;
    			}
    		}
    	}		
	}	
	@media (min-width:$screen-xs) and (max-width: $screen-sm) {
	//background:none;
		.home-banner-wrap {
		    width: 100%;
		    height: auto;
			background:none;
			padding: 0px;
			ul{width:100%;height:auto;padding:0px;
			  li{width:100%;height:auto;}
			  }
			}
		.home-banner-sub-full {
		    width: 100%;
		    margin:0px auto;
		    display: block;
		    height: auto;
		    position: relative;
		    background:none;
		    top:10px;
	    	right:0px;
	    	img{display:block;margin:0px auto;max-width:100%;height:auto;border:solid $white 4px;}
    	}	
	}
}
#footer{
	background: #3c3c3c;

	.footerholder{overflow: hidden;
    padding: 45px 0 25px 6px;}
    //background: $bgfooter url(/wp-content/themes/srisaas/theme-img/bg-footer.jpg) repeat-x !important;
    min-height:300px;
    padding-top: 40px;
    margin-bottom:0px;
    p{margin:0px;/*color:$footerbloglistcolor;*/color:#fff;font-size: 1em;}
    h3{text-transform: uppercase;
    color: $white;
    display: block;
    font-size: 1.9em;
    letter-spacing: 0.5px;
    border-bottom: 1px solid $footerh3border;
    padding-bottom: 7px;
    margin-bottom: 16px;
    font-weight: bold;
	}
	a{/*color:$footerbloglistcolor;*/color:#fff;font-size: 1em;}
	ul{
		padding:0px;
		li{
			margin-top:10px;
			a{/*color:$footerbloglistcolor;*/color:#fff;color: #dcd7d7;font-size: 0.9em;
			 &:hover{text-decoration:none;}
			}
		}
	}
	.social_icons{
		li{float:left; margin-right:20px;
			a{
				color: #ba9c63;
    			font-size: 1.4em;
    			text-decoration:none;
			}	
		}
	}
	@media screen and (max-width: $screen-xs) {
		img{max-width:100%;height:auto;}
	}
	@media (min-width:$screen-xs) and (max-width: $screen-sm) {
	 	img{max-width:100%;height:auto;}
	}

	.textwidget.address p {
	    font-size: 0.9em !important;
	    color: #dcd7d7 !important;
	}

}
#footerbottom{
	margin-top:0px;
	min-height: 89px;
    height: auto;
    /*background: $bgfooterbottom url(/wp-content/themes/srisaas/theme-img/bg-footer-bottom.jpg) repeat-x !important;*/
    background: $bgfooterbottom;
    color: $textfooterbottom !important;
    p{margin:0px;margin-top:10px;}
    .left{
    	p{text-align:left; color:$white;font-size: 0.7em;}
    }
    .right{
    	p{text-align:right;}
    	p a{text-align:right; color:$white; text-decoration:none;font-size: 0.7em;}
    }	
}

.homepageintro.row {
    background-color: #f8f8d2;
    background-color: #171717;
    border-top: solid 4px #ba9c63;
    border-bottom: solid 4px #ba9c63;
    h3 {
    color: #ba9c63;
    font-weight: 600;
    font-size: 1.2em;
    margin-top: 18px;
    
    font-weight: 400;
    font-size: 1.7em;

	}
    p{
    	font-size: 1em;
    	font-size: 1.06em;
    	color: #bfb4b4;
    	a {
		    color: #838c82;
		    color: #eac4a3;
		    font-weight: 400;
		    text-decoration: none;

		    &.goldlinks{
	    	    margin-right: 20px;
			    color: #fff !important;
		    }
		}
    }
}

#homepage{
	img{border:solid 2px $gold;}
	h2{color:$gold;font-weight:600;font-size: 3em;text-align:center;width:100%;}
	h3{color:$gold;font-weight:600;font-size:1.2em;}
	h5{font-size:1.6em;font-weight:600;}
	a{
		color:$antigold;
	    font-weight: 400;
		text-decoration: none;
		&.readmore{background:$bgcolor2;color:$white;padding:4px 8px;text-decoration:none;
			&:hover{text-decoration:none;}
			}
	}
	p{font-size:1em;}
	a.viewmore{color:$gold;margin-left:30px;font-weight:600;text-decoration:underline; }

	ul {
		  &.home-client-list li {
		    width: 16%;
		    margin-right: 2%;
		    float: left;
		    list-style: none;
		    img {
		      width: 100%;
		      height: auto;
		    }
		    h4 {
		      text-align: center;
		      padding: 0px !important;
		      margin-top: 7px !important;
		      font-weight: 600;
		      color:$gold;
	          font-size: 0.8em;
		    }
		    a {
		      text-decoration: none;
		    }
		    //
			@media (min-width: $screen-xs) and (max-width: $screen-md) {
			width: 45%;
			margin-bottom: 20px;
			}	
			@media screen and (max-width: $screen-xs) {
			width: 90%;
			margin-bottom: 20px;
			img{height:auto;}
			}		

		    //
		  }
		  &.home-project-list li {
		    width: 16%;
		    margin-right: 2%;
		    float: left;
		    list-style: none;
		    img {
		      width: 100%;
		      height: auto;
		      max-height: 108px;
		    }
		    h4 {
		      text-align: center;
		      padding: 0px !important;
		      margin-top: 7px !important;
		      font-weight: 600;
		      color:$gold;
	          font-size: 0.8em;
		    }
		    a {
		      text-decoration: none;
		    }

		    //
		    @media (min-width: $screen-xs) and (max-width: $screen-md) {
			width: 45%;
			margin-bottom: 20px;
			}	
			@media screen and (max-width: $screen-xs) {
			width: 90%;
			margin-bottom: 20px;
			}	
			
		    //
		  }
	}
	
	.hs-desc {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
    margin-top: 20px;
    background-color: #f8f8d2;
	padding-left: 15px;
	padding-bottom: 4px;
	padding-top: 8px;
	border: solid 2px #dddd92;
	    p{
	    	// color:#d46c6c;
	    	color: #52527a;
	    }
	}

	.homepagebox{
		width:100%;
		height:100%;
		background-color: $bgcolor3;
		border-radius:10px;
		border:2px solid $gold;
		.home_offer_link_title {
			  text-decoration: none;
			  color: $gold;
			  font-weight: 800;
			  font-size: 13px;
			  padding-left: 5px;
			}
		}
		
	#homepageservicetabs{
		ul{
			li{
				float:left;
				width:15%;
				width: 28%;
				text-align:center;
			    margin-right: 5%;
				padding-top:3px;
					padding-bottom:3px;
					padding-left:5px;
					padding-right:5px;
					background-color:#ba9c63;
				a{
					color:#fff;
					
				}


			}
			
		}
		@media screen and (max-width: $screen-xs) {
			ul{
				li{
					width: 95%;
					    margin-bottom: 20px;
				}
			}
		}
		/* @media (min-width:$screen-xs) and (max-width: $screen-sm) {
		 	ul{
				li{
					width: 95%;
					    margin-bottom: 20px;
				}
			}
		}*/
		
		}

	}	
	.homeservicesection{
		h2{margin-left:15px;}

		ul.hp-checkitout{
				padding:0px;
				margin:0px;
				width:100%;
					li{
						padding:0px;
						margin:0px;
						width:100%;
						a{
							text-decoration: none;
						}
			}

		}
		img{


			@media screen and (max-width: $screen-xs) {
			margin:0px auto;
			display:block;
			}
		}

		h3{
			@media screen and (max-width: $screen-xs) {
			text-align:center;
			}
		}

		.postsection{
			margin-top:20px;
			a.readmore{
				margin-right:20px;
			}
		}
	}



	#hs-design{
		background-color: #3c3c3c;
		background-color: #2d2d2d;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='464' height='464' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%231c1905' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%232a2b02'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
		padding-top: 30px;
    	padding-bottom: 30px;
    	-webkit-animation: slide 20s linear infinite;
    	p{color:#af9e9e;color:#dadada;}
	}
	#hs-development{
		background-color: #3c3c3c;
		background-color: #2d2d2d;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='464' height='464' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%231c1905' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%232a2b02'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
		padding-top: 30px;
    	padding-bottom: 30px;
    	-webkit-animation: slide 20s linear infinite;
    	p{color:#af9e9e;color:#dadada;}
	}
	#hs-marketing{
		background: #3c3c3c;
		// background-image:url(/wp-content/themes/srisaas/theme-img/wave.svg);
		// background-size: 100%;
	    border-top: solid 5px #ba9c63 !important;
	    border-bottom: solid 5px #ba9c63 !important;
	    padding-top: 40px;
	    padding-bottom: 40px;
	    p{
	    	color:#dadada;
	    }
	}

	#hs-ourworks{
		background: #3c3c3c;
		// background-image:url(/wp-content/themes/srisaas/theme-img/wave.svg);
		// background-size: 100%;
	    border-top: solid 5px #ba9c63 !important;
	    border-bottom: solid 5px #ba9c63 !important;
	    padding-top: 40px;
	    padding-bottom: 40px;
	    p{
	    	color:#dadada;
	    	font-size:0.9em;
	    	a{
	    		color:#dadada;
	    		border-bottom:solid 1px #ba9c63;
	    		text-decoration: none;
	    	}
	    }

	    h4{
	    	color:#ba9c63;
	    	font-weight: 600;
	    }
	}

	@-webkit-keyframes slide {
    from { background-position: 0 0; }
    to { background-position: -400px 0; }
	}

	.homeservicesectionnew{
		h2{color:$gold;font-weight:600;font-size: 3em;text-align:center;width:100%;}
		h3{color:$gold;font-weight:600;font-size:1.2em;}
		h5{font-size:1.6em;font-weight:600;}
		a{
			color:$antigold;
		    font-weight: 400;
			text-decoration: none;
			&.readmore{background:$bgcolor2;color:$white;padding:4px 8px;text-decoration:none;
				&:hover{text-decoration:none;}
				}
		}
		p{font-size:1em;}


		h3{
			a{
				color: #ba9c63 !important;
			    font-weight: 600 !important;
			    font-size: 1em !important;
			    font-family: "Open Sans Condensed", sans-serif !important;
			}
		}

		p{
			a{
				color:#c6c7b4 !important;
			}
		}
		
		.hs-desc {
		    width: 90%;
		    margin-left: 5%;
		    margin-bottom: 30px;
		    margin-top: 20px;
		    //background-color: #f8f8d2;
		    background-color: #3c3c3c;
			padding-left: 15px;
			padding-bottom: 4px;
			padding-top: 8px;
			//border: solid 2px #dddd92;
			border: solid 2px #ba9c63;
			    p{
			    	// color:#d46c6c;
			    	//color: #52527a !important;
			    	color: #fff !important;
			    	font-size: 1.06em;
			    	font-style: italic;
			    	padding-right:20px;
			    }
		}

		@media screen and (max-width: $screen-sm) {
			p{
				padding-left: 5%;
	    		width: 90%;
			}
		}
	}
/*
header{
	background:$bgcolor1;
	.logo{
		color:#FFF;
		img{margin-top:10px;margin-bottom:10px;}
		}
	.static{
		color:#FFF;
		ul{float:right;}
		li{float:left;list-style:none;margin-right:30px;margin-top:5px;}
		li a{color:$textcolor1;}
		}
	.buttons{
		margin-top:5px;
		color:#FFF;
		ul{float:right;}
		li{float:left;list-style:none;margin-right:30px;}
		li a{padding-left:10px;padding-right:10px;color:#FFF;border-radius:10px;}
		li a:hover{text-decoration:none;background:$bgcolor2;}
		.signup{background:$signupbutton;border:solid 1px #FFF;}
		.signup a{text-decoration:none;color:$signupcolor;}
		.login{border:solid 1px #FFF;}
		.login a{text-decoration:none;color:#fff;}
		}	
	
}
.banner{
	background:url('../../img/4rentkw.png');
	margin-bottom:0px;
	padding:0px;
	h1{color:#FFF;text-align:center;font-family:$font-stack;font-weight: 800;text-shadow: 2px 2px 1px $bgcolor2;}
}
.topad{
	background:$bgcolor3;
	padding:0px;
	margin-bottom:0px;
	img{display:block;margin:0px auto;padding-top:5px;padding-bottom:5px;max-width:96%;}
}

.search{
	background:$bgcolor4;
	border-top:solid 2px $bordercolor1;
	border-bottom:solid 2px $bordercolor1;
	padding-bottom:10px;
	margin-bottom:20px;
	.searchop{
		input[type=text]{max-width:40%;margin-right:4%;}
	}
	.searchbutton{
		input[type=submit]{margin-top:15px;background:$bgcolor2;color:$textcolor1;}
	}

	#searchoptions{
			margin-top:10px;
			margin-bottom:20px;
			ul li.active a{background:$bgcolor5;color:#FFF;}
			ul li{ float:left;list-style:none;margin-right:30px;
				a{
					font-weight:600; padding:4px 10px;text-decoration:none;background:#bfb1b1;color:#FFF;
				}	
						
				
			}
		}
}

.listings{
	.listitem{
		background:$bgcolor3;
		margin-bottom:20px;
		padding-top: 10px;
		border:solid 3px $bgcolor2;
		overflow:hidden;
		p{color:$textcolor2;font-family:$font-stack-body;font-size:1em;}
		.listphoto{
			img{margin:0px auto;margin-top:3px;display:block;width:90%;height:auto;}
		}
		.listdesc{
			p strong{color:$textcolor4;}
			h4{color:$textcolor4;margin-top:3px;font-family:$font-stack;font-weight:600;font-size:1.3em;}
			h5 a{font-style:italic;color:$textcolor3;font-weight:600;}
		}
		.btn{background:$bgcolor2;color:#FFF;}
	}

}

#listingpage{
	margin-top:40px;
	min-height:700px;
		h1{color:$bgcolor1;}
		p.listingcategory a{font-style:italic;color:$green;}
		#slideshow{
			img{max-width:90%;display:block;margin:0px auto;border:solid 2px $bgcolor1;}
			margin-bottom:40px;
		}
		#thumbs{
			li{width:23%;float:left;list-style:none;}
			img{max-width:96%;border:solid 1px $bgcolor1; }
		}
		#phonenumber{
			p{font-size:1.2em;font-weight:600;}
			span.call{background:$bgcolor1;color:$textcolor1;padding-right:4px;padding-left:5px;padding-top: 4px;
    padding-bottom: 3px;}
			p strong{background:$green;color:$textcolor1;padding-left:4px;padding-right:6px;padding-top: 4px;
    padding-bottom: 3px;}
		}
	.enqform{ h6{font-weight:600;font-size:1.1em;color:$bgcolor1;}
		.btn.sendenquiry{background:$bgcolor2;color:#FFF;}
		
	}	
}

#pagepage{
	margin-top:40px;
	min-height:700px;
}

#contactpage{
	margin-top:40px;
	min-height:700px;
}

prefooter{
	background:$bgcolor2;
	display:block;
	padding-top:4px;
	p{text-align:center;color:$textcolor1;margin-bottom:4px;
		a{color:$textcolor1;font-weight:600;
			&:hover{color:$textcolor1;text-decoration:none;}
		}
	}
}

footer{
	background:$bgcolor1;
	color:$textcolor1;
	li{list-style:none;}
	li.left{float:left;}
	li.right{float:right;}
	a{color:$textcolor1;}
	a:hover{color:$textcolor1;text-decoration:none;}
}

.sidebar{
	img{display:block;margin:0px auto;max-width:96%;}
}

.easyPaginateNav{
	.page{
    background: $bgcolor2;
    padding-left: 5px;
    padding-right: 5px;
    color: #FFF;
    font-weight: 600;
    font-size: 1.2em;
    margin-right: 4px;
	&.current{background: $bgcolor1;
	  padding-left: 5px;
	  padding-right: 5px;
	  color: #FFF;
	  font-weight: 600;
	  font-size: 1.2em;
	}
	}
	.first,.last,.prev,.next{
		background: $bgcolor2;
    padding-left: 5px;
    padding-right: 5px;
    color: #FFF;
    font-weight: 600;
    font-size: 1.2em;
    margin-right: 4px;
	}
}
*/

.wpcf7{
	input[type="text"] {
	    width: 90% !important;
	}
	input[type="email"] {
	    width: 90% !important;
	}
	textarea {
	    width: 90% !important;
	}
}
#websitesByPrice{
	margin-top:20px;

	.eachOption{
		background:#3c3c3c;
		border: 4px solid #ba9c63;
		padding:0px;

		.pricetitle{
			color:#fff;
			text-align:center;
		}

		.priceicon{
			display: block;
    		text-align: center;
    		color:#ba9c63;
    		font-size:2em;
		}

		.pricedesc{
			p{
				width:100% !important;
				color:#e0c1c1;
				font-size:1em;
				text-align:center;
			}
		}

		.priceclick{
		    background: #ba9c63;
		    width: 100%;
    		//position: absolute;
    		p{
    			width:100% !important;
    			text-align:center;
    			a{text-decoration:none;text-align:center; color:#fff;font-size:0.8em;}
    		}
		}
	}
}

#webPackageDetails{
	background:#3c3c3c;
    border: solid 2px #ba9c63;

	h3{
		margin-top:20px;
	    margin-bottom: 24px;
	}
	h4{
		text-align:left !important;
		color:#ba9c63 !important;
	    font-size: 1.2em !important;
   		font-weight: 600 !important;
	}
	p{
		font-size: 1em !important;
		color:#e2dddd !important
	}
	
	a{
			color: #ba9c63;
		    text-decoration: none;
		    background-color: transparent;
		    border-bottom: dotted 1px white;
	}

	@media screen and (max-width: $screen-xs) {
	margin-bottom:20px;
	}
}


#faqOffer{
	
	strong{
		&.faqHeader{
			color: #f5ead4;
			background-color: #af1b1b;
			border-radius: 10px;
			padding: 3px;
			text-decoration: none;
			padding-left: 7px;
			padding-right: 7px;
			margin: 0px auto;
			display: block;
			width: 35%;
			text-align: center;
			font-size: 1.2em;
		}
	}

	h6{
		&.packagequestion {
				    color: $textcolor3;
				    font-size: 1.6em;
				    font-weight: 600;
				    letter-spacing: 1px;
				    margin-bottom: 0px;
				    padding-bottom: 20px;
				    text-align: center;
				    text-transform: initial;
				}
	}
	p{
		width: 100% !important;
	}
	@media screen and (max-width: $screen-xs) {
		strong{
			&.faqHeader{
				width: 100% !important;
			    text-align: left;
			    padding:0px;
			    padding-left:2px;
			    border-radius: 0px;
			}
		}

		h6{
		&.packagequestion {
				    color: $textcolor3;
				    font-size: 1em !important;
				    font-weight: 600;
				    letter-spacing: 1px;
				    margin-bottom: 0px;
				    padding-bottom: 20px;
				    text-align: left !important;
				    text-transform: initial;
			}
		}

		p{
			text-align:left !important;
			font-size:1.1em !important;
		}
	}
}

.worksItem{
	margin-top:15px;
	margin-bottom:15px;
	background:#3c3c3c;
}